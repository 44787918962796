import Cookies from 'js-cookie'
export const siteUrl = document.body.getAttribute('data-site-url');
// ==== DATE =====
export const todayDate = new Date();

export const dateObjectFromAtom = (atomDate) => {
    return new Date(atomDate);
}
export const getNiceDateFromAtom = (atomDate) => {
    let date = dateObjectFromAtom(atomDate);
    return date.getDate() + '/' + ( date.getMonth() + 1 ) + '/' + date.getFullYear();
}
export const getLongDateFromAtom = (atomDate) => {
    return dateObjectFromAtom(atomDate).toDateString();
}
export const getAtomFromNice = (niceDate) => {
    // input format: DD/MM/YYYY
    let d = niceDate.split('/');
    return new Date(d[2],(d[1]-1),d[0]);
}
export const modifyDate = (date, month_modifier) => {
    // return a date object modified by month modifier
    return new Date( date.getFullYear(), (date.getMonth() + month_modifier + 1), date.getDate() )
}
export const getUrlParam = (param) => {
    const url = window.location.href;
    if ( url.split('?')[1] !== undefined ) {
        return url.split('?')[1].split(param+'=')[1];
    } else {
        return '';
    }
}
export const getDays = (number) => {
    const days = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
    return days[number]
}
export const getMonthFromNumber = (number) => {
    var months = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
    return months[number].slice(0,3); // months are zero indexed!
}
export const toKebab = (str) => {
    return str.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g).map(x => x.toLowerCase()).join('-');
}
export const toPascal = (s) => {
    // only works with space separated words
    let w = s.split(' ');
    let result = [];
    w.forEach(word => {
        word = word.replace(' ','');
        let a = word.split('')[0].toUpperCase();
        let b = word.slice(1).toLowerCase();
        result.push(a+b);
    });
    return result.join('');
}

export const bindEscapeKeyListener = () => {
    document.onkeydown = function(evt) {
        evt = evt || window.event;
        let isEscape = false;
        if ("key" in evt) {
            isEscape = (evt.key === "Escape" || evt.key === "Esc");
        } else {
            isEscape = (evt.keyCode === 27);
        }
        if (isEscape) {
            document.body.classList.remove('overflow-hidden');
            document.querySelectorAll('.close-on-esc').forEach($el => {
                $el.classList.remove('active');
            });
            destroyLightbox();
        }
    };
}
export const actionClass = (selector, action, className) => {
    document.querySelectorAll(selector).forEach(($el) => {
        if ( action == 'toggle' ) {
            $el.classList.toggle(className);
        }
        if ( action == 'add' ) {
            $el.classList.add(className);
        }
        if ( action == 'remove' ) {
            $el.classList.remove(className);
        }
    })
}
export const setAttributesToNode = (node, attrs) => {
    // accepts an array of objects with keys: attr and type
    attrs.forEach(attr => {
        node.setAttribute(attr.attr,attr.value);
    });
    return node;
}
export const append = ($el, node, classString, html) => {
    const $child = document.createElement(node);
    $child.classList = classString;
    $child.innerHTML = html;
    $el.appendChild($child);
}
// convert id (as shown in shopify URL and stored in cms) to base64.
// 'type' is either 'Product' or 'ProductVariant' and IS CASE SENSITIVE
export const base64 = (id, type) => {
    return btoa('gid://shopify/'+type+'/' + id);
};

export const classToggleEvent = (eventName, selector, targetElement, className) => {
    document.querySelectorAll(selector).forEach(($el) => {
        $el.addEventListener(eventName, (e) => {
            actionClass(targetElement,'toggle',className);
        });
    });
}
export const getStyle = (el, prop) => {
    //console.trace(el)
    try {
        if (typeof getComputedStyle !== 'undefined') {
            return window.getComputedStyle(el, null).getPropertyValue(prop);
        } else {
            return el.currentStyle[prop];
        }
    } catch (e) {
        console.error(e)
    }
}
export const breakpoints = {
    '2xs':375,
    'xs':480,
    "sm":640,
    "md":768,
    "lg":1024,
    "xl":1280,
    '2xl':1360
}
export const bindPopupListeners = () => {
    /* ================================= *
    by querying '.js-toggle-popup' selector, look for data-popup attr where popup id is stored.
    add/remove active class and toggle prevent scrolling on body
    ** ================================= */
    document.querySelectorAll('.js-toggle-popup').forEach($el => {
        
        let $popup = [];
        let attr = $el.getAttribute('data-popup');
        // data-popup attribute can accept a comma separated string of ids. Test if this is the case.
        if ( attr.includes(',') ) {
            let popups = attr.split(',');

            popups.forEach(popup => {
                $popup.push('#'+popup);
            });

            $popup = $popup.join(',');
        } else {
            $popup = '#'+attr;
        }

        $el.addEventListener('click', (e) => {
            //console.log(document.body.classList)
            actionClass($popup,'toggle','active');
            actionClass('body', 'toggle', 'overflow-hidden')
        });
    });
}
export const buildRevealDelays = ($el) => {
    /* ================================= *
    Query parent elements with class of 'reveal', get child reveal-items,
    loop through and create transition-delay attrs for each. Then opacity on these elements
    can be set in css, e.g. by parent active class.
    ** ================================= */
    document.querySelectorAll('.reveal').forEach($parent => {
        let i = 0;
        $parent.querySelectorAll('.reveal-item').forEach($revealItem => {
            let delay = 0.04 * i;
            $revealItem.style.cssText = 'transition-delay: '+delay+'s';
            i++;
        });
    });
}
export const navByPage = (section, entryId) => {
    document.querySelectorAll('header nav a').forEach(($el) => {
        if ( section == 'projects' ) {
            // its a project, nav by entryid
            if ( $el.classList.contains('entry-'+entryId) ) {
                $el.classList.add('select');
            } else {
                $el.classList.remove('select');
            }
        } else {
            // nav by section
            if ( $el.classList.contains(section+'-section') ) {
                $el.classList.add('select');
            } else {
                $el.classList.remove('select');
            }
        }

    });
}
export const tagTooltip = ($el) => {
    $el.addEventListener('click', () => {
        let $tooltip = $el.querySelector('.tooltip');
        $tooltip.classList.add('active');
        setTimeout(() => {
            $tooltip.classList.remove('active');
        },5000)
    });
}
export const checkTouch = () => {
    // Check Device
    let isTouch = ('ontouchstart' in document.documentElement);

    if ( isTouch ) {
        document.body.classList.add('touch');
        return true;
    }
    else {
        document.body.classList.add('no-touch');
        return false;
    }
}
export const isNew = (atomDate, interval) => {
    // return true if date is within the last [interval] months
    return todayDate < modifyDate(dateObjectFromAtom(atomDate), interval)
}

export const isFuture = (atomDate) => {
    // return true if date is in the future
    return todayDate < dateObjectFromAtom(atomDate);
}
export const setCookie = (cookie) => {
    Cookies.set('hudson_cart', JSON.stringify(cookie));
}
export const readCookie = () => {
    const cookie = Cookies.get('hudson_cart')
    if (cookie) {
        return JSON.parse(cookie);
    } else {
        console.error(`Cookie hudson_cart not found`)
    }
}
// hide elements that might be above
const elementsToHide = ['.fader'];
const elementsToResetZ = ['.z-max'];

export const destroyLightbox = () => {
    document.body.classList.remove('overflow-hidden');
    document.querySelectorAll('.lightbox').forEach($lightbox => $lightbox.remove() );
    elementsToHide.forEach(className => document.querySelectorAll(className).forEach($el => {
        $el.classList.remove('hidden');
    }));
    elementsToResetZ.forEach(className => document.querySelectorAll(className).forEach($el => {
        $el.classList.add(className.slice(1));
    }));
}
export const buildLightbox = ($el) => {
    const alt = $el.getAttribute('alt').length ? $el.getAttribute('alt') : ''
    let caption = $el.getAttribute('data-caption') ? $el.getAttribute('data-caption') : ''
    caption = caption.replace('-','–')

    const $lightbox = document.createElement('div');
    $lightbox.classList.add('lightbox');
    $lightbox.classList.add('z-max');
    $lightbox.innerHTML = `<div class="ol ab-fill object-cover bg-white opacity-75"></div>
                    <div class="content">
                        <div class="">
                            <img class="block shadow-xl rounded-md" src="${$el.getAttribute('src')}" alt="${alt}" />`
                            + (caption ? `<div class="w-3/4 mx-auto text-center text-sm p-5 text-black">${caption}</div>` : ``) +
                            `<a class="p-3 block mx-auto relative brand-rounded mt-3 shadow-lg w-16 h-16 bg-white icon-center filter hover:invert cross-icon-center icon-dark"></a>
                        </div>
                    </div>`
    document.body.appendChild($lightbox);

    document.body.classList.add('overflow-hidden');

    elementsToHide.forEach(className => document.querySelectorAll(className).forEach($el => {
        $el.classList.add('hidden');
    }));
    elementsToResetZ.forEach(className => document.querySelectorAll(className).forEach($el => {
        $el.classList.remove(className.slice(1));
    }));

    document.querySelector('.lightbox').addEventListener('click', () => destroyLightbox());

}
export const buildIframeVideo = ($el, ready) => {
    if ( $el.classList.contains('js-play-video') ) {
        const iframeDiv = document.createElement('div');

        iframeDiv.innerHTML = '<iframe class="ab-fill" width="1920" height="1080" src="' + $el.getAttribute('data-src') + '" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>';
        $el.classList.remove('js-play-video');
        $el.classList.add('active');
        $el.insertBefore(iframeDiv, $el.lastChild);
    }
}
export const gql = async (query, variables) => {
    const params = {
        method:'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify({ query, variables })
    }
    const res = await fetch('/api', params)
    const json = await res.json()

    return json
}
export const getUriSegs = () => {
    return window.location.href.split(siteUrl).slice(1)[0].split('/');
}
export const rebuildAttribute = (selector, attr) => {
    document.querySelectorAll(selector).forEach($el => {
        let savedAttr = $el.getAttribute(attr);
        $el.removeAttribute(attr);
        $el.setAttribute(attr, savedAttr)
    })
}
